import { CircularProgress, ButtonGroup, Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField} from '@mui/material';
import React, { useEffect } from 'react';
import { RootState } from '../../app/store';
import { User } from "@jonjon1123/bowlr-admin-common";
import { updateEmail, updateEmailReset } from './usersSlice';
import EmailIcon from '@mui/icons-material/Email';
import GetAppIcon from '@mui/icons-material/GetApp';
import { generatePath, Link } from 'react-router-dom';
import { paths } from '../../components/Router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DataGrid, GridCellParams, GridColDef, GridRowsProp } from '@mui/x-data-grid';

interface DialogState {
  userId: string;
  currentEmail: string;
}

export function Users() {
  const dispatch = useAppDispatch();

  const {
    usersById,
    userIds,
    loading,
    error,
    message
  } = useAppSelector((state: RootState) => state.users);

  const { env } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    if (error || message) {
      dispatch(enqueueSnackbar({
        message: error ?? message,
        variant: error ? 'error' : 'success',
        preventDuplicate: true,
      }));
      dispatch(updateEmailReset());
    }
  }, [error, message, dispatch]);

  const [open, setOpen] = React.useState(false);
  const [dialogState, setDialogState] = React.useState<DialogState | null>(null);

  const handleClickOpen = (userId: string, email: string) => {
    setOpen(true);
    setDialogState({
      userId: userId,
      currentEmail: email,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setDialogState(null);
  };

  const schema = yup.object().shape({
    userId: yup.string().required(),
    email: yup.string().email().required(),
  });

  type FormData = {
    email: string;
    userId: string;
  };

  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = handleSubmit(({ email, userId }) => {
    console.log(`Setting ${userId} to ${email}`);
    dispatch(updateEmail(env, userId, email));
  });

  const rows: GridRowsProp = userIds.map((userId: string) => {
    const user: User = usersById[userId];
    return ({
      id: user.id,
      name: user.name,
      email: user.email,
      appVersion: user.appVersion,
      deviceType: user.deviceType,
      emailVerified: user.emailVerified,
      lastSignInDateTime: user.lastSignInDateTime ? new Date(user.lastSignInDateTime) : '',
      creationDateTime: user.creationDateTime ? new Date(user.creationDateTime) : '',
      proStatus: user.proStatus,
      proProduct: user.proProductId,
      user: user,
    });
  });

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', flex: 1.3 },
    { field: 'name', headerName: 'Name', flex: .75 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'appVersion', headerName: 'Version', flex: .6 },
    { field: 'deviceType', headerName: 'Device', flex: 1 },
    { field: 'emailVerified', headerName: 'Email Verified', flex: .75 },
    { field: 'proStatus', headerName: 'Pro Status', flex: .65 },
    { field: 'proProduct', headerName: 'Pro Product', flex: .65 },
    { field: 'user', headerName: 'Actions', flex: .65, disableColumnMenu: true, sortable: false, renderCell: (params: GridCellParams) => (
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button component={Link} to={generatePath(paths.DATA_IMPORTER_WITH_PARAM, { id: (params.value as User).id })}><GetAppIcon fontSize="small" /></Button>
        <Button onClick={() => {handleClickOpen((params.value as User).id, (params.value as User).email)}}><EmailIcon fontSize="small" /></Button>
      </ButtonGroup>
    ),}
  ];

  return (<div style={{ height: '90vh', flexGrow: 1 }}>
    <DataGrid
      rows={rows} 
      columns={columns}
    />
    <Dialog open={open} onClose={handleClose}>
    <form onSubmit={onSubmitHandler}>
      <DialogTitle>Update Email</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label="UserId"
          id="userId"
          InputProps={{
            readOnly: true,
          }}
          {...register('userId')}
          defaultValue={dialogState?.userId}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          autoComplete="email"
          autoFocus
          {...register('email')}
          error={errors.email ? true : false}
          helperText={errors.email?.message}
          defaultValue={dialogState?.currentEmail}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading && <CircularProgress color='secondary' size={24} />}
          {!loading && "Update Email"}
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  </div>);
}



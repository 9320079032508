import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { RootState } from '../../app/store';
import { TextField, Button, Card, CardContent, Box, FormControl, InputLabel, MenuItem, Select, Container, CircularProgress, CardHeader, SelectChangeEvent, styled } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Env, EnvValue } from "@jonjon1123/bowlr-admin-common";
import { useParams } from 'react-router-dom';
import { doImport, fetchSourceUsers, fetchTargetUsers, importReset } from './dataImporterSlice';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Alert, Autocomplete } from '@mui/material';

interface ParamTypes {
  id: string
}

type FormData = {
  sourceUserId: string;
  targetUserId: string;
}; 

export function DataImporter() {
  const { id } = useParams<ParamTypes>();
  const dispatch = useAppDispatch();

  const schema = yup.object().shape({
    sourceUserId: yup.string().required(),
    targetUserId: yup.string().required(),
  });

  const { sourceUserIds, sourceLoading, sourceError, targetUserIds, targetLoading, targetError, loading, message, error } = useAppSelector(
    (state: RootState) => ({
      sourceUserIds: state.dataImporter.source.userIds,
      sourceLoading: state.dataImporter.source.loading,
      sourceError: state.dataImporter.source.error,
      targetUserIds: state.dataImporter.target.userIds,
      targetLoading: state.dataImporter.target.loading,
      targetError: state.dataImporter.target.error,
      loading: state.dataImporter.loading,
      message: state.dataImporter.message,
      error: state.dataImporter.error,
    }),
    shallowEqual
  );

  const [sourceEnv, setSourceEnv] = useState(Env.release().value);
  const [targetEnv, setTargetEnv] = useState(Env.debug().value);

  const { handleSubmit, formState: { errors }, control, setValue } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchSourceUsers(sourceEnv));
  }, [dispatch, sourceEnv]);

  useEffect(() => {
    dispatch(fetchTargetUsers(targetEnv))
  }, [dispatch, targetEnv]);

  useEffect(() => {
    if (sourceUserIds) {
      setValue("sourceUserId", id ?? "");
    }

    if (targetUserIds) {
      setValue("targetUserId", "");
    }
  }, [id, setValue, sourceUserIds, targetUserIds]); 

  useEffect(() => {
    if (message) {
      dispatch(enqueueSnackbar({
        message: message,
        variant: error ? 'error' : 'success',
        preventDuplicate: true,
      }));
      dispatch(importReset());
    }
  }, [message, error, dispatch]); 

  const onSubmitHandler = handleSubmit(({ sourceUserId, targetUserId }) => {
    dispatch(doImport(sourceEnv, sourceUserId, targetEnv, targetUserId));
  });

  const StyledContainer = styled('div')(
    ({ theme }) => `
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    `,
  );

  const StyledForm = styled('form')(
    ({ theme }) => `
      width: '100%',
      marginTop: ${theme.spacing(1)},
    `,
  );

  const SubmitButton = styled(Button)(
    ({ theme }) => `
      margin: ${theme.spacing(3, 0, 2)}
    `,
  );

  const ErrorAlert = styled(Alert)(
    ({ theme }) => `
      arginTop: "16px",
    `,
  );
  
  return (
    <Container component="main" maxWidth="sm">
      <StyledContainer>
        <StyledForm onSubmit={onSubmitHandler}>
          <Card>
            <CardHeader
              action={sourceLoading ? <CircularProgress size={32} /> : null}
              title="Source"
            />
            <CardContent style={{paddingTop: 0}}>              
              <Box marginY="16px">
                <FormControl variant="outlined" fullWidth={true}>
                  <InputLabel>Environment</InputLabel>
                  <Select
                    value={sourceEnv}
                    onChange={(event: SelectChangeEvent<EnvValue>) => {
                      setSourceEnv(event.target.value as EnvValue);
                      setValue("sourceUserId", "");
                    }}
                    id="sourceEnvironment"
                    name="sourceEnvironment"
                    label="Environment"
                  >
                    {Env.all().map((env: Env) => {
                    return (<MenuItem value={env.value} key={env.value}>{env.name}</MenuItem>)
                  })}
                  </Select>
                </FormControl>
              </Box>
              <Controller
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="sourceUserId"
                    options={sourceUserIds}
                    renderInput={(params) => 
                      <TextField {...params}
                        required
                        label="User" 
                        variant="outlined"
                        error={errors.sourceUserId ? true : false}
                        inputRef={ref}
                      />}
                    onChange={(event: any, newValue: string | null) => {
                      field.onChange(newValue)
                    }}
                    onInputChange={(event, newInputValue) => {
                      field.onChange(newInputValue)
                    }}
                    loading={sourceLoading}
                  />
                )}
                defaultValue={id ?? undefined}
                name="sourceUserId"
                control={control}
              />
              {sourceError && (
                <ErrorAlert severity="error">{sourceError}</ErrorAlert>
              )}
            </CardContent>
          </Card>
          <Box textAlign='center'>
            <ArrowDownwardIcon fontSize="large" style={{margin: ".5em"}} />
          </Box>
          <Card>
            <CardHeader
              action={targetLoading ? <CircularProgress size={32} /> : null}
              title="Target"
            />
            <CardContent style={{paddingTop: 0}}>
              <Box marginY="16px">
                <FormControl variant="outlined" fullWidth={true}>
                  <InputLabel>Environment</InputLabel>
                  <Select
                    id="targetEnvironment"
                    name="targetEnvironment"
                    value={targetEnv}
                    onChange={(event: SelectChangeEvent<EnvValue>) => {
                      setTargetEnv(event.target.value as EnvValue);
                      setValue("targetUserId", "");
                    }}
                    label="Environment"
                  >
                    {Env.all().map((env: Env) => {
                    return (<MenuItem value={env.value} key={env.value}>{env.name}</MenuItem>)
                  })}
                  </Select>
                </FormControl>
              </Box>
              <Controller
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    id="targetUserId"
                    options={targetUserIds}
                    renderInput={(params) => 
                      <TextField {...params}
                        required 
                        label="User" 
                        variant="outlined"
                        inputRef={ref}
                        error={errors.targetUserId ? true : false}
                      />}
                    onChange={(event: any, newValue: string | null) => {
                      field.onChange(newValue);
                    }}
                    loading={targetLoading}
                  />
                )}
                name="targetUserId"
                defaultValue={undefined}
                control={control}
              />
              {targetError && (
                <ErrorAlert severity="error">{targetError}</ErrorAlert>
              )}
            </CardContent>
          </Card>
          <SubmitButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading && <CircularProgress size={24} />}
            {!loading && "Import"}
          </SubmitButton>
        </StyledForm>
      </StyledContainer>
    </Container>
  );
}

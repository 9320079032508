import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { Env, EnvValue, User, ImporterRequest } from "@jonjon1123/bowlr-admin-common";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getErrorMessage } from '../../utils';

interface DataImporterState {
  source: {
    usersById: Record<string, User>,
    userIds: string[],
    error: string | undefined,
    loading: boolean,
  },
  target: {
    usersById: Record<string, User>,
    userIds: string[],
    error: string | undefined,
    loading: boolean,
  },
  loading: boolean,
  message: string | undefined,
  error: boolean,
}

const initialState: DataImporterState = {
  source: {
    usersById: {},
    userIds: [],
    error: undefined,
    loading: false,
  },
  target: {
    usersById: {},
    userIds: [],
    error: undefined,
    loading: false,
  },
  loading: false,
  message: undefined,
  error: false,
};

export const dataImporterSlice = createSlice({
  name: 'dataImporter',
  initialState,
  reducers: {
    fetchSourceUsersInit: (state) => {
      state.source.loading = true;
    },
    fetchSourceUsersSuccess: (state, action: PayloadAction<User[]>) => {
      state.source.loading = false;
      state.source.error = undefined;
      action.payload.forEach(user => {
        state.source.usersById[user.id] = user;
      });
      state.source.userIds = action.payload.map(user => user.id);
    },
    fetchSourceUsersFail: (state, action: PayloadAction<string>) => {
      state.source.loading = false;
      state.source.error = action.payload;
    },
    fetchTargetUsersInit: (state) => {
      state.target.loading = true;
    },
    fetchTargetUsersSuccess: (state, action: PayloadAction<User[]>) => {
      state.target.loading = false;
      state.target.error = undefined;
      action.payload.forEach(user => {
        state.target.usersById[user.id] = user;
      });
      state.target.userIds = action.payload.map(user => user.id);
    },
    fetchTargetUsersFail: (state, action: PayloadAction<string>) => {
      state.target.loading = false;
      state.target.error = action.payload;
    },
    importInit: (state) => {
      state.loading = true;
      state.message = undefined;
      state.error = false;
    },
    importSuccess: (state) => {
      state.loading = false;
      state.message = "User data has been imported!"
      state.error = false;
    },
    importFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.message = action.payload;
      state.error = true;
    },
    importReset: (state) => {
      state.loading = false;
      state.message = undefined;
      state.error = false;
    },
  },
});

export const { 
  fetchSourceUsersInit, 
  fetchSourceUsersSuccess, 
  fetchSourceUsersFail, 
  fetchTargetUsersInit, 
  fetchTargetUsersSuccess, 
  fetchTargetUsersFail,
  importInit,
  importSuccess,
  importFail,
  importReset,
} = dataImporterSlice.actions;

export default dataImporterSlice.reducer;

export const fetchSourceUsers = (env: EnvValue) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchSourceUsersInit());

    const _env: Env | undefined = Env.all().find((checkEnv: Env) => checkEnv.value === env);

    if (_env) {
      let users:User[] = [];
      try {
        const getAllUsers = httpsCallable<string, string>(getFunctions(), 'https-users-getAllV1');
        users = users.concat(JSON.parse((await getAllUsers(JSON.stringify({ env: _env.value }))).data));
      } catch (error) {
        return dispatch(fetchSourceUsersFail(getErrorMessage(error)));
      }
      return dispatch(fetchSourceUsersSuccess(users));
    } else {
      throw new Error("Invalid env");
    }
  }
}

export const fetchTargetUsers = (env: EnvValue) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchTargetUsersInit());
    let users:User[] = [];
    try {
      const getAllUsers = httpsCallable<string, string>(getFunctions(), 'https-users-getAllV1');
      users = users.concat(JSON.parse((await getAllUsers(JSON.stringify({ env: env }))).data));
    } catch (error) {
      return dispatch(fetchTargetUsersFail(getErrorMessage(error)));
    }
    return dispatch(fetchTargetUsersSuccess(users));
  }
}

export const doImport = (sourceEnv: EnvValue, sourceUserId: string, targetEnv: EnvValue, targetUserId: string) => { 
  return async (dispatch: Dispatch<any>) => {
    dispatch(importInit());
    const request: ImporterRequest = {
      sourceEnv: sourceEnv,
      sourceUserId: sourceUserId,
      targetEnv: targetEnv,
      targetUserId: targetUserId,
    }
    try {
      const importer = httpsCallable<string, string>(getFunctions(), 'https-importerV1');
      const error = JSON.parse((await importer(JSON.stringify(request))).data)
      if (error) {
        return dispatch(importFail(error));
      } else {
        return dispatch(importSuccess());
      }
    } catch (error) {
      return dispatch(importFail(getErrorMessage(error)));
    }    
  }
}
import { useEffect } from 'react';
import { RootState } from '../../app/store';
import { fetchUsers } from '../users/usersSlice';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import { User } from "@jonjon1123/bowlr-admin-common";
import { Grid, CardContent, Typography, CircularProgress, Card } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { shallowEqual } from 'react-redux';

let didInit = false;

export function Home() {
  const dispatch = useAppDispatch();

  const {
    usersById,
    userIds,
    error,
    loading,
    message
  } = useAppSelector((state: RootState) => state.users, shallowEqual);

  const { env } = useAppSelector((state: RootState) => state);

  // Init users data. Only need to do this once and doing it here since this is the
  // first page users hit after loging in.
  useEffect(() => {
    if (!didInit) {
      didInit = true;
      dispatch(fetchUsers(env))
    }
  }, [dispatch, env])

  useEffect(() => {
    if (error || message) {
      dispatch(enqueueSnackbar({
        message: error ?? message,
        variant: error ? 'error' : 'success',
        preventDuplicate: true,
      }));
    }
  }, [error, message, dispatch]);

  let proUpgrade:number = 0;
  let proUpgradeYearly:number = 0;
  let proUpgradeMonthly:number = 0;
  userIds.forEach((userId: string) => {
    const user: User = usersById[userId];
    if (user.proProductId === 'app.bowlr.proupgrade') {
      proUpgrade++;
    } else if (user.proProductId === 'app.bowlr.pro.yearly') {
      proUpgradeYearly++;
    } else if (user.proProductId === 'app.bowlr.pro.monthly') {
      proUpgradeMonthly++;
    }
  });

  return (
    <div>
      <Grid container spacing={1} columns={3} sx={{mb: 1}}>
        <Grid item xs={1}>{DashboardDataCard(proUpgrade, "app.bowlr.proupgrade", loading)}</Grid>
        <Grid item xs={1}>{DashboardDataCard(proUpgradeYearly, "app.bowlr.pro.yearly", loading)}</Grid>
        <Grid item xs={1}>{DashboardDataCard(proUpgradeMonthly, "app.bowlr.pro.monthly", loading)}</Grid>
      </Grid>
    </div>
  );
}

function DashboardDataCard(data: number | undefined, label: string, isLoading: boolean = true) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant='h4' color="secondary" gutterBottom>{label}</Typography>
        {isLoading && <CircularProgress size={50} />}
        {!isLoading && <Typography variant='h1' component="div">{data}</Typography>}
      </CardContent>
    </Card>
  );
}
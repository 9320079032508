import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { EnvValue } from "@jonjon1123/bowlr-admin-common";

export const envSlice = createSlice({
  name: 'env',
  initialState: EnvValue.debug,
  reducers: {
    setEnv: (state, action: PayloadAction<EnvValue>) => {
      return (action.payload);
    },
  },
});

export const { setEnv } = envSlice.actions;

export const selectEnv = (state: RootState) => state.env;

export default envSlice.reducer;
import { createRoot } from 'react-dom/client';
import { persistor, store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { verifyAuth } from './features/auth/authSlice';
import { PersistGate } from 'redux-persist/integration/react';
import Theme from './components/Theme';

store.dispatch(verifyAuth())

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Theme />
      </PersistGate>
    </Provider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(App()); 

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

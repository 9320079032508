import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { 
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, 
  persistStore,
  persistCombineReducers} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from '../features/auth/authSlice';
import usersReducer from '../features/users/usersSlice';
import envReducer from '../features/env/envSlice';
import dataImporterReducer from '../features/data-importer/dataImporterSlice';
import notifierReducer from '../features/notifier/notifierSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'env']
}

const persistedReducer = persistCombineReducers<any>(persistConfig, {
  auth: authReducer,
  users: usersReducer,
  env: envReducer,
  dataImporter: dataImporterReducer,
  notifier: notifierReducer,
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { initializeApp } from "firebase/app";
import { getAnalytics } from 'firebase/analytics';

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'Email already in use',
  EMAIL_INVALID: 'Email is invalid',
  EMAIL_NOT_FOUND: 'Email was not found',
  PASSWORD_INVALID: 'Invalid credentials',
  USER_DISABLED: 'User disabled',
  TOO_MANY_REQUESTS: 'Too many attempts made, try again later',
  EXPIRED_ACTION_CODE: 'The invitation link has expired, get in touch with your administrator',
  INVALID_ACTION_CODE: 'The invitation link has expired, get in touch with your administrator',
  QUOTA_EXCEEDED_STORAGE: 'Internal server error, get in touch with your administrator',
  UNAUTHENTICATED_STORAGE: 'Unauthenticated, please authenticate and try again',
  UNAUTHORIZED_STORAGE: 'Unauthorized, you are not authorized to perform this action',
};

var firebaseConfig = {
  apiKey: "AIzaSyBUF4gUKyAm2HQNp0hyioez1iyO6QPfCp0",
  authDomain: "bowlr-admin.firebaseapp.com",
  databaseURL: "https://bowlr-admin.firebaseio.com",
  projectId: "bowlr-admin",
  storageBucket: "bowlr-admin.appspot.com",
  messagingSenderId: "203483360269",
  appId: "1:203483360269:web:791c095f27a3fdfcfcf944",
  measurementId: "G-RGQNW9L9ND"
};

export const app = initializeApp(firebaseConfig);
getAnalytics(app);

// Comment out this line when not using Firebase emulator for functions
//firebase.functions().useEmulator("localhost", 5001);
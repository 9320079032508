import {
  Button,
  TextField,
  CircularProgress,
  Box
} from '@mui/material';
import { shallowEqual } from 'react-redux';
import { resetAuth, signIn } from './authSlice';
import { RootState } from '../../app/store';
import { Redirect, useLocation } from 'react-router-dom';
import { paths } from '../../components/Router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthStatus } from "@jonjon1123/bowlr-admin-common";
import { useEffect } from 'react';
import { enqueueSnackbar } from '../notifier/notifierSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';


interface LocationState {
  from: {
    pathname: string;
  };
}

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

const SignIn = () => {
  const dispatch = useAppDispatch();

  const { error, loading, isAuthed } = useAppSelector(
    (state: RootState) => ({
      error: state.auth.error,
      loading: state.auth.loading,
      isAuthed: state.auth.status === AuthStatus.success
    }),
    shallowEqual
  );

  useEffect(() => {
    if (error) {
      dispatch(enqueueSnackbar({
        message: error,
        variant: 'error',
        preventDuplicate: true,
      }));
      dispatch(resetAuth());
    }
  }, [error, dispatch]); 

  type FormData = {
    email: string;
    password: string;
  };

  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = handleSubmit(({ email, password }) => {
    dispatch(signIn(email, password));
  });

  let location = useLocation<LocationState>();
  let { from } = location.state || { from: { pathname: paths.ROOT } };

  return (      
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      {isAuthed && <Redirect to={from} />}
      <form onSubmit={onSubmitHandler}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          autoComplete="email"
          autoFocus
          {...register('email')}
          error={errors.email ? true : false}
          helperText={errors.email?.message}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          id="password"
          {...register('password')}
          autoComplete="current-password"
          error={errors.password ? true : false}
          helperText={errors.password?.message}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={loading}
          sx={{
            marginTop: 2
          }}
        >
          {loading && <CircularProgress size={24} />}
          {!loading && "Sign In"}
        </Button>
      </form>
    </Box>
  );
}

export default SignIn;

import React from 'react';
import {
  Divider,
  FormControl,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Theme,
  Toolbar,
  Typography,
  useTheme,
  CircularProgress,
  Box,
  styled,
  SelectChangeEvent,
  ListItemButton,
  CSSObject,
  CssBaseline
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GetAppIcon from '@mui/icons-material/GetApp';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { shallowEqual } from 'react-redux';
import { signOut } from '../features/auth/authSlice';
import { RootState } from '../app/store';
import { Link, Redirect } from 'react-router-dom';
import { Env } from "@jonjon1123/bowlr-admin-common";
import { paths } from './Router';
import { setEnv } from '../features/env/envSlice';
import { EnvValue, AuthStatus } from "@jonjon1123/bowlr-admin-common";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { fetchUsers } from '../features/users/usersSlice';
import RefreshIcon from '@mui/icons-material/Refresh';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type LayoutProps = {
  children?: React.ReactNode;
};

export function Layout(props: LayoutProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);

  const { isAuthed, env, usersLoading } = useAppSelector(
    (state: RootState) => ({
      isAuthed: state.auth.status === AuthStatus.success,
      env: state.env,
      usersLoading: state.users.loading
    }),
    shallowEqual
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const handleRefresh = () => {
    dispatch(fetchUsers(env))
  };

  const handleEnvChange = (event: SelectChangeEvent<unknown>) => {
    const env = event.target.value as EnvValue;
    dispatch(setEnv(env));
    dispatch(fetchUsers(env));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {!isAuthed && <Redirect to={paths.SIGNIN} />}
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography 
            variant="h6" 
            noWrap 
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            Bowlr Admin
          </Typography>
          {usersLoading && <CircularProgress sx={{m: .5}} size={32} />}
          {!usersLoading && <IconButton 
            aria-label="refresh"
            onClick={handleRefresh}
          >
            <RefreshIcon />
          </IconButton>}
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={env}
              onChange={handleEnvChange}
            >
              {Env.all().map((env: Env) => {
              return (<MenuItem value={env.value} key={env.value}>{env.name}</MenuItem>)
            })}
            </Select>
          </FormControl>
          <IconButton 
            aria-label="sign out"
            onClick={handleSignOut}
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton key={"home"} component={Link} to={paths.ROOT}>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
          <ListItemButton key={"users"} component={Link} to={paths.USERS}>
            <ListItemIcon><PeopleAltIcon /></ListItemIcon>
            <ListItemText primary={"Users"} />
          </ListItemButton>
          <ListItemButton key={"data-importer"} component={Link} to={paths.DATA_IMPORTER}>
            <ListItemIcon><GetAppIcon /></ListItemIcon>
            <ListItemText primary={"Data Importer"} />
          </ListItemButton>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}

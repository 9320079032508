import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { RootState } from '../../app/store';
import { app, FIREBASE_RESPONSE } from '../../firebase';
import { AuthStatus } from "@jonjon1123/bowlr-admin-common";
import { getAuth, signInWithEmailAndPassword, User } from 'firebase/auth';
import { getErrorMessage } from '../../utils';

interface AuthState {
  userId: string | undefined,
  status: AuthStatus,
  loading: boolean,
  error: string | undefined,
  restoring: boolean,
  restoringError: boolean | undefined,
};

const initialState: AuthState = { 
  userId: undefined, 
  status: AuthStatus.unknown,
  loading: false,
  error: undefined,
  restoring: false,
  restoringError: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInInit: (state) => {
      state.loading = true;
    },
    signInFail: (state, action: PayloadAction<{errorMessage: string}>) => {
      state.loading = false;
      state.error = action.payload.errorMessage;
      state.status = AuthStatus.error;
    },
    signOutInit: (state) => {
      return initialState;
    },
    signOutSuccess: (state) => {
      return initialState;
    },
    restoreAuthInit: (state) => {
      state.restoring = true;
    },
    restoreAuthSuccess: (state) => {
      state.restoring = false;
      state.restoringError = undefined;
      state.status = AuthStatus.success;
    },
    restoreAuthFail: (state) => {
      state.restoring = false;
      state.restoringError = true;
      state.status = AuthStatus.error;
    },
    fetchUserDataInit: (state) => {
      state.loading = true;
    },
    fetchUserDataSuccess: (state, action: PayloadAction<{userId: string}>) => {
      state.loading = false;
      state.error = undefined;
      state.userId = action.payload.userId;
      state.status = AuthStatus.success;
    },
    fetchUserDataFail: (state, action: PayloadAction<{errorMessage: string}>) => {
      state.loading = false;
      state.error = action.payload.errorMessage;
    },
    resetAuth: (state) => {
      // https://redux-toolkit.js.org/usage/immer-reducers#resetting-and-replacing-state
      return initialState;
    }
  },
});

export const { signInInit, signInFail, signOutInit, signOutSuccess, restoreAuthInit, restoreAuthSuccess, restoreAuthFail, fetchUserDataInit, fetchUserDataSuccess, fetchUserDataFail, resetAuth } = authSlice.actions;

export const isAuthed = (state: RootState) => {
  return state.auth.status === AuthStatus.success;
};

export default authSlice.reducer;

export const signIn = (email: string, password: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(signInInit());
    try {
      await signInWithEmailAndPassword(getAuth(app), email, password);
    } catch (error) {
      return dispatch(signInFail({ errorMessage: getErrorMessage(error) }));
    }

    const currentUser: User | null = getAuth(app).currentUser;

    if (currentUser && !currentUser.emailVerified) {
      const errorMessage = FIREBASE_RESPONSE.USER_DISABLED;
      return dispatch(signInFail({ errorMessage: errorMessage }));
    }

    return dispatch(fetchUserData());
  }
}

export const verifyAuth = () => {
  return (dispatch: Dispatch<any>) => {
    getAuth(app).onAuthStateChanged((user) => {
      dispatch(restoreAuthInit());
  
      if (user !== null) {
        return dispatch(restoreAuthSuccess());
      }
  
      dispatch(restoreAuthFail());
      return dispatch(signOut());
    });
  }
}

export const signOut = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(signOutInit());
    await getAuth(app).signOut();
    dispatch(signOutSuccess());
  }
}

export const fetchUserData = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(fetchUserDataInit());

    let currentUser: User | null;
    try {
      currentUser = getAuth(app).currentUser;
    } catch (error) {
      dispatch(signOut());
      return dispatch(fetchUserDataFail({ errorMessage: getErrorMessage(error) }));
    }

    if (!currentUser) {
      return dispatch(signOut());
    }

    return dispatch(fetchUserDataSuccess({ userId: currentUser.uid }));
  }
}
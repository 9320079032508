import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Home } from '../features/home/Home';
import SignIn from '../features/auth/SignIn';
import PrivateRoute from './PrivateRoute';
import { Users } from '../features/users/Users';
import { DataImporter } from '../features/data-importer/DataImporter';
import { NotFound } from '../features/not-found/NotFound';

// The Switch tag below evaluates the routes in the order they are listed. Whichever one matches first is the
// one that will get rendered. The exact property makes it so the exact location of the browser has to match 
// the path that is being passed in to the path property.
function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.SIGNIN} component={SignIn} />
        <PrivateRoute exact path={paths.USERS} component={Users} />
        <PrivateRoute exact path={paths.DATA_IMPORTER_WITH_PARAM} component={DataImporter} />
        <PrivateRoute exact path={paths.ROOT} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export const paths = {
  SIGNIN: "/signin",
  ROOT: "/",
  USERS: "/users",
  DATA_IMPORTER: "/data-importer",
  DATA_IMPORTER_WITH_PARAM: "/data-importer/:id?",
}

export default Router;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { RootState } from '../../app/store';
import { Env, UpdateEmailRequest } from "@jonjon1123/bowlr-admin-common";
import { EnvValue } from "@jonjon1123/bowlr-admin-common";
import { User } from "@jonjon1123/bowlr-admin-common";
import { getErrorMessage } from '../../utils';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface UsersState {
  usersById: Record<string, User>,
  userIds: string[],
  loading: boolean,
  error: string | undefined,
  message: string | undefined,
}

const initialState: UsersState = {
  usersById: {},
  userIds: [],
  loading: false,
  error: undefined,
  message: undefined,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersInit: (state) => {
      state.loading = true;
    },
    fetchUsersSuccess: (state, action: PayloadAction<User[]>) => {
      state.loading = false;
      state.error = undefined;
      action.payload.forEach(user => {
        state.usersById[user.id] = user;
      });
      state.userIds = action.payload.map(user => user.id)
    },
    fetchUsersFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateEmailInit: (state) => {
      state.loading = true;
      state.message = undefined;
      state.error = undefined;
    },
    updateEmailSuccess: (state) => {
      state.loading = false;
      state.message = "User email has been updated!"
      state.error = undefined;
    },
    updateEmailFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.message = action.payload;
      state.error = action.payload;
    },
    updateEmailReset: (state) => {
      state.loading = false;
      state.message = undefined;
      state.error = undefined;
    },
  },
});

export const { fetchUsersInit, fetchUsersSuccess, fetchUsersFail, updateEmailInit, updateEmailSuccess, updateEmailFail, updateEmailReset } = usersSlice.actions;

export const selectUsers = (state: RootState) => state.users.usersById;

export default usersSlice.reducer;

export const fetchUsers = (env: EnvValue) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchUsersInit());

    const _env: Env | undefined = Env.all().find((checkEnv: Env) => checkEnv.value === env);

    if (_env) {
      let users:User[] = [];
      try {
        const getAllUsers = httpsCallable<string, string>(getFunctions(), 'https-users-getAllV1');
        let firebseUsers:User[] = JSON.parse((await getAllUsers(JSON.stringify({ env: _env.value }))).data);
        firebseUsers = firebseUsers.map((firebaseUser:User) => {
          // If there is a proProductId set, use that. Otherwise, if the user is paid, we can assume it is the old one-time purchase
          // product id since the code to set the proProductId was implemented at the same time as the new subscription product ids.
          // That means that anyone that is paid but has a null proProductId purchased the old one-time purchase product id.
          firebaseUser.proProductId = firebaseUser.proProductId ?? (firebaseUser.proStatus === 'paid' ? 'app.bowlr.proupgrade' : null)
          return firebaseUser;
        })
        users = users.concat(firebseUsers);
      } catch (error) {
        return dispatch(fetchUsersFail(getErrorMessage(error)));
      }
      return dispatch(fetchUsersSuccess(users));
    } else {
      throw new Error("Invalid env");
    }
  }
}

export const updateEmail = (env: EnvValue, userId: string, newEmail: string) => { 
  return async (dispatch: Dispatch<any>) => {
    dispatch(updateEmailInit());
    const request: UpdateEmailRequest = {
      env: env,
      userId: userId,
      newEmail: newEmail
    }
    try {
      const updateEmail = httpsCallable<string, string>(getFunctions(), 'https-users-updateEmailV1');
      await updateEmail(JSON.stringify(request));
      return dispatch(updateEmailSuccess());
    } catch (error) {
      return dispatch(updateEmailFail(getErrorMessage(error)));
    }    
  }
}
import React from 'react';
import Notifier from '../features/notifier/Notifier';
import { SnackbarProvider } from 'notistack';
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import Router from './Router';

function Theme() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#556cd6',
          },
          secondary: {
            main: '#90CAF9',
          },
          /*
          background: {
            default: '#fff',
          },
          */
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Notifier />
        <Router />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default Theme;
